// This file was generated by "rake i18n:generate-js". Do not edit.

import translations from './en.json'
import { I18n } from "i18n-js";

// We don't allow the user to switch locale, so we only load one.
const i18n = new I18n(translations)
i18n.locale = 'en'
i18n.defaultLocale = 'en'

// And we set I18n to be globally available in `window` in order to be used
// by both vanilla JS and VueJS apps.
window.I18n = i18n
